
import { Alert, Button, Col, Form, Modal, Row, Table, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { apiCall } from '../../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useSelector } from 'react-redux';
import { modalError } from '../../../Component/Modal/ModalLayout';


function ListOutletCategory() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [outlet, setOutlet] = useState([]);
    const [categoryPrice, setCategoryPrice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Outlet Kategori Harga';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
             sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kategori Harga',
            dataIndex: 'name',
            key: "name",
             sorter: false,
            render: (id, record) => {
                if (record.price_category != null)
                    return record.price_category.name;
                else
                    return null;
            },
        },
        {
            title: 'Kategori Diskon',
            dataIndex: 'name',
            key: "name",
             sorter: false,
            render: (id, record) => {
                if (record.discount_category != null)
                    return record.discount_category.name;
                else
                    return null;
            },
        },
        {
            title: 'Nama Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
             sorter: false,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: "action",
             sorter: false,
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/outletcategory?search=' + search + '&page=' + page.pagination.current, 'all');
        getApi("GET", '/outlet?nopage=true', 'outlet');
        getApi("GET", '/pricecategory?nopage=true', 'pricecategory');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type, data = null) => {
        setIsAlert(false);
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setTextAlert(res.message);
                switch (type) {
                    case 'outlet':
                        var row = [];
                        var item = res.data;
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: item[i].outlet_id,
                                title: item[i].outlet_name,
                            })
                        }
                        setOutlet(row);
                        setLoading(false);
                        break;
                    case 'pricecategory':
                        var row2 = [];
                        var item2 = res.data.rows;
                        for (let i = 0; i < item2.length; i++) {
                            row2.push({
                                value: item2[i].price_category_id,
                                title: item2[i].name,
                            })
                        }
                        setCategoryPrice(row2);
                        setLoading(false);
                        break;
                    case 'all':
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'insert':
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    case 'update':
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    case 'delete':
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            } else {
                modalError(res.message);
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/outletcategory/' + v.outlet_id, 'delete'),
        });
    }

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                outlet_id: data.outlet_id,
                price_category_id: data.price_category_id,
                discount_category_id: data.discount_category_id,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            var data = {
                outlet_id: dataModal.outlet_id,
                price_category_id: ev.price_category_id,
                discount_category_id: ev.discount_category_id,
            }
            getApi('PUT', '/outletcategory', 'update', data);
        } else {
            getApi('POST', '/outletcategory', 'insert', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }


    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Outlet Kategori Harga</h3>
            <Row style={{ marginBottom: 10 }}>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Kategori Harga" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_category_price" layout="vertical" onFinish={onFinish}>
                    {
                        dataModal === null &&
                        <Form.Item
                            name="outlet_id"
                            label="Pilih Outlet"
                            rules={[
                                {
                                    required: dataModal !== null ? false : true,
                                    message: 'Nama Outlet kosong!'
                                },
                            ]}
                        >
                            <TreeSelect
                                showSearch
                                style={{
                                    width: '100%',
                                }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                placeholder="Pilih Outlet"
                                allowClear
                                treeNodeFilterProp='title'
                                treeDefaultExpandAll
                                treeData={outlet}
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        name="price_category_id"
                        label="Pilih Kategori Harga"
                        rules={[
                            {
                                required: false,
                                message: 'Nama Kategori Harga kosong!'
                            },
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Pilih Kategori Harga"
                            allowClear
                            treeNodeFilterProp='title'
                            treeDefaultExpandAll
                            treeData={categoryPrice}
                        />
                    </Form.Item>
                    <Form.Item
                        name="discount_category_id"
                        label="Pilih Kategori Diskon"
                        rules={[
                            {
                                required: false,
                                message: 'Nama Kategori Diskon kosong!'
                            },
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Pilih Kategori Harga"
                            allowClear
                            treeNodeFilterProp='title'
                            treeDefaultExpandAll
                            treeData={categoryPrice}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ListOutletCategory;