import { Button, DatePicker, Form, Input, Modal, Select, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { currencyFormat } from "../../Utils/formatCurrency";
import { useSelector } from "react-redux";
import { dateFormat } from "../../Utils/formatDate";
import { modalSuccess } from "../../Component/Modal/ModalLayout";
import { formatNumber } from "../../Utils/formatNumber";
import { exportToExcelTransaction } from "../../Utils/Excel/TransactionExportExcel";
import Search from "antd/es/input/Search";
const { RangePicker } = DatePicker;

function Invoice() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const [outlet, setOutlet] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [dataPaymentMethod, setDataPaymentMethod] = useState([]);
    const [search, setSearch] = useState(null);
    const [dataModal, setDataModal] = useState(null);

    const [valueOutlet, setValueOutlet] = useState();
    const [valueDate, setValueDate] = useState();
    const [isModalTrans, setIsModalTrans] = useState(false);


    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const titlePage = 'Faktur';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Transaksi',
            dataIndex: 'transaction_code',
            key: "transaction_code",
            
            render: (id, record) => {
                return record.transaction_code;
            },
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",
            
            render: (id, record) => { return dateFormat(record.created_at); },
        },
        {
            title: 'Nama Member',
            dataIndex: 'user',
            key: "user",
            
            render: (id, record) => {
                if (record.customer !== null) {
                    return record.customer.name;
                } else {
                    return null;
                }
            },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            
            render: (id, record) => { return record.outlet.outlet_name; },
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
            
            render: (id, record) => {
                var qty = 0;
                if (record.transaction_products.length > 0) {
                    for (let i = 0; i < record.transaction_products.length; i++) {
                        qty += Number(record.transaction_products[i].qty);
                    }
                }
                return qty;
            },
        },
        {
            title: 'Harga',
            dataIndex: 'grand_total',
            key: "grand_total",
            
            render: (id, record,) => {
                return currencyFormat(record.grand_total);
            },
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: "point",
            
            render: (id, record,) => {
                if (record.point !== null) {
                    return formatNumber(record.point.point) + ' point';
                } else {
                    return '0 point';
                }
            },
        },
        {
            title: 'Pembayaran',
            dataIndex: 'method_payment',
            key: "method_payment",
            
            render: (id, record) => {
                if (record.payment_method === 'tunai') {
                    return 'TUNAI';
                } else {
                    return record.device_payment;
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" style={{ margin: 5, backgroundColor: "DodgerBlue" }} onClick={() => openEditModal(record, index)}>
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" style={{ margin: 5 }} onClick={() => delInvoice(record, index)}>
                            <b style={{ marginLeft: 5 }}>Delete</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        var vOutlet = 'outletId=' + valueOutlet;
        var vDate = '';
        if (valueDate !== null && valueDate !== undefined) {
            vDate = 'startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
        }
        var url = '/transaction?page=' + page.pagination.current;

        if (valueOutlet !== undefined) {
            url += '&' + vOutlet;
        }

        if (valueDate !== undefined) {
            url += '&' + vDate;
        }

        if (search !== null) {
            url += '&search=' + search;
        }
        getApi({ method: "GET", url, type: 'transaction' });
        if (outlet.length === 0) {
            getApi({ method: 'GET', url: '/outlet?nopage=true', type: 'outlet' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = ({ method, url, type, dataRequest, index }) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: dataRequest,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'transaction') {
                    setData(res.data.rows);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.count
                        }
                    });
                    setLoading(false);
                } else if (type === 'transactionnopage') {
                    exportToExcelTransaction(res.data.rows);
                    modalSuccess('Success');
                    setIsLoading(false);
                    setLoading(false);
                } else if (type === 'outlet') {
                    var o = [];
                    for (let i = 0; i < res.data.length; i++) {
                        o = [...o, {
                            value: res.data[i].outlet_id,
                            label: res.data[i].outlet_name
                        }];
                    }
                    setOutlet(o);
                    setLoading(false);
                } else if (type === 'akunoutlet') {
                    var op = res.data.rows;
                    var opArr = ['EDC BCA', 'EDC MANDIRI', 'EDC BNI', 'EDC BRI', 'KAS'];
                    var row = [];

                    for (let i = 0; i < opArr.length; i++) {
                        var arr = op.filter(o => {
                            var opArrLowerCase = opArr[i].toLowerCase();
                            return o.akun.akun_name.toLowerCase().includes(opArrLowerCase);
                        });
                        for (let i2 = 0; i2 < arr.length; i2++) {
                            row.push({
                                value: arr[i2].akun.akun_no,
                                label: opArr[i] === 'KAS' ? 'TUNAI' : opArr[i],
                            });
                            // Find the corresponding item in the original op array and set the label
                            let originalIndex = op.findIndex(o => o.akun.akun_no === arr[i2].akun.akun_no);
                            if (originalIndex !== -1) {
                                op[originalIndex].label = opArr[i] === 'KAS' ? 'TUNAI' : opArr[i];
                            }
                        }
                    }

                    setDataPaymentMethod(op);
                    setPaymentMethod(row);
                    setLoading(false);
                } else if (type === 'excel') {
                    window.location.replace(res.path);
                    setLoading(false);
                } else if (type === 'delete') {
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                    modalSuccess('Hapus Invoice Berhasil');
                    setLoading(false);
                }else if(type === 'update'){
                    data[index].device_payment = dataRequest.device_payment;
                    data[index].payment_method = dataRequest.payment_method;
                    data[index].notes= dataRequest.notes;
                    data[index].akun_no= dataRequest.akun_no;
                    setData(data);
                    modalSuccess(res.message);
                    setIsModalTrans(false);
                    setLoading(false);
                    setDataModal(null);
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const delInvoice = (ev, index) => {
        getApi({
            method: 'DELETE',
            url: '/transaction/' + ev.transaction_id,
            type: 'delete',
            index
        });
    };

    const openEditModal = (ev, index) => {
        getApi({ method: 'GET', url: '/outletakun?outletId=' + ev.outlet_id, type: 'akunoutlet' });
        setDataModal({data:ev,index});
        form.setFieldsValue({
            transaction_code: ev.transaction_code,
            notes: ev.notes,
            akun_no: ev.akun_no
        })
        setIsModalTrans(true);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const findSearch = () => {
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const onFinish = (ev) => {
        var device_payment;
        var payment_method;
        for (let i = 0; i < dataPaymentMethod.length; i++) {
            const item = dataPaymentMethod[i];
            if (item.akun.akun_no === ev.akun_no) {
                if (item.label === "TUNAI") {
                    device_payment = item.akun.akun_name;
                    payment_method = "tunai";
                } else {
                    device_payment = item.label;
                    payment_method = "card";
                }
                break;
            }
        }

        var dataRequest = {
            device_payment,
            payment_method,
            notes: ev.notes,
            akun_no: ev.akun_no
        }
        if(dataModal !== null){
            getApi({method:'PUT', url:'/transaction/'+dataModal.data.transaction_id, type:'update',dataRequest, index:dataModal.index});
        }
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            <h3>Data Transaksi</h3>
            <Spin spinning={isLoading}>
                <div >
                    <Select
                        mode="multiple"
                        showSearch
                        style={{
                            width: 300,
                            marginBottom: 10
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Pilih Outlet"
                        onChange={setValueOutlet}
                        options={outlet} />
                    <br />
                    <RangePicker
                        style={{
                            width: 300,
                            marginBottom: 10
                        }}
                        onChange={(ev, date) => setValueDate(date)}
                    />

                    <br />
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => findSearch()}>
                        <b style={{ marginLeft: 5 }}>Cari</b>
                    </Button>
                    <br />
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        size="medium"
                        style={{ width: 300, marginBottom: 10 }}
                        onChange={(ev) => setSearch(ev.target.value)}
                        onSearch={findSearch}
                    />
                </div>
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    pagination={page.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
                <Modal title="Detail Transaksi" open={isModalTrans} onOk={form.submit} onCancel={() => setIsModalTrans(false)} okText="Simpan" >
                    <Form name="form" form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name="transaction_code"
                            label="Kode Transaksi"
                            rules={[
                                {
                                    required: true,
                                    message: 'kode Transaksi kosong!'
                                },
                            ]}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item
                            name="akun_no"
                            label="Metode Pembayaran"
                            rules={[
                                {
                                    required: true,
                                    message: 'Metode Pembayaran kosong!'
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Pilih Metode Pembayaran"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={paymentMethod}
                            />
                        </Form.Item>
                        <Form.Item
                            name="notes"
                            label="Deskripsi"
                            rules={[
                                {
                                    required: false,
                                    message: 'Deskripsi kosong!'
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div>
    );
}


export default Invoice;