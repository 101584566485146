import { currencyFormat } from "../formatCurrency";

function isDateBetween(dateToCheck, startDate, endDate) {
    return dateToCheck >= startDate && dateToCheck <= endDate;
}

// Fungsi untuk memeriksa apakah waktu berada di antara dua waktu
function isTimeBetween(timeToCheck, startTime, endTime) {
    return timeToCheck >= startTime && timeToCheck <= endTime;
}

// Fungsi untuk memeriksa apakah promo aktif berdasarkan data promosi dan waktu saat ini
export function isPromoActive(promoData) {
    if (!promoData || !promoData.group_product_promo) {
        return false;
    }

    const { group_product_promo, qty } = promoData;

    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', { hour12: false });
    const currentDateString = currentDate.toISOString().split('T')[0];

    const isValidDate = group_product_promo.product_promo_dates.some(dateRange => {
        return isDateBetween(currentDateString, dateRange.date_start, dateRange.date_end);
    });

    const isValidTime = group_product_promo.product_promo_times.some(timeRange => {
        return isTimeBetween(currentTime, timeRange.time_start, timeRange.time_end);
    });
    // Check if the promotion is active based on date and time
    const isActive = isValidDate && isValidTime;

    // Return true if it's a priority promo or if it's an active promo
    return isActive;
}

export function isPriceActive(priceData) {
    if (!priceData || !priceData.group_product_price) {
        return false;
    }

    const { group_product_price, qty } = priceData;

    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', { hour12: false });
    const currentDateString = currentDate.toISOString().split('T')[0];

    const isValidDate = group_product_price.product_price_dates.some(dateRange => {
        return isDateBetween(currentDateString, dateRange.date_start, dateRange.date_end);
    });

    const isValidTime = group_product_price.product_price_times.some(timeRange => {
        return isTimeBetween(currentTime, timeRange.time_start, timeRange.time_end);
    });

    // Check if the promotion is active based on date and time
    const isActive = isValidDate && isValidTime;

    // Return true if it's a priority promo or if it's an active promo
    return isActive;
}


// Fungsi untuk menghitung harga dengan diskon
function calculateDiscountedPrice(price, discountPercent) {
    const discountAmount = Number(price) * (Number(discountPercent) / 100);
    const finalPrice = Number(price) - discountAmount;
    return finalPrice;
}

export function posPriceDiscount(item, is_ecommerce = null) {
    // Cek apakah ada promosi produk
    if (item.product_promos !== undefined && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            return currencyFormat(priorityPromo.price_sale);
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            return currencyFormat(validPromo.price_sale);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        if (is_ecommerce === true)
            return currencyFormat(item.product_prices[0].price_ecommerce);
        else {
            const validPrice = item.product_prices
                .filter(price => isPriceActive(price))
                .sort((a, b) => {
                    const dateA = new Date(a.group_product_price.product_price_dates[0]?.date_start || 0);
                    const dateB = new Date(b.group_product_price.product_price_dates[0]?.date_start || 0);
                    return dateA - dateB;
                })[0];
            if (validPrice) {
                return currencyFormat(validPrice.price_after_discount);
            }else{
                return currencyFormat(item.product_prices[0].price_after_discount);
            }
        }
    }

    if (item.product_category_prices.length > 0) {
        const pcp = item.product_category_prices;
        const dis = pcp.find(o => o.salesAdjustmentType === 'ITEM_DISCOUNT_TYPE');
        const pric = pcp.find(o => o.salesAdjustmentType === 'ITEM_PRICE_TYPE');

        if (pric !== undefined && pric !== null) {
            if (pric.discount !== undefined && pric.discount !== null) {
                if (dis.discount !== undefined && dis.discount !== null) {
                    const priceAfterDiscount = calculateDiscountedPrice(Number(pric.discount.price), Number(dis.discount.discount_percent));
                    return currencyFormat(priceAfterDiscount);
                }
            } else {
                return currencyFormat(pric.price);
            }
        }
    }

    if (item.discount !== undefined && item.discount !== null) {
        if (Number(item.discount.price_after_discount) === 0) {
            return currencyFormat(item.price);
        } else {
            return currencyFormat(item.discount.price_after_discount);
        }
    }

    return currencyFormat(item.price);
}

export function posPriceBeforeDiscount(item) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            return currencyFormat(priorityPromo.price_normal);
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            return currencyFormat(validPromo.price_normal);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        return currencyFormat(item.product_prices[0].price_normal);
    }

    if (item.product_category_prices.length > 0) {
        const pric = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_PRICE_TYPE');
        if (pric && pric.discount) {
            return currencyFormat(pric.discount.price);
        }
    }

    if (item.discount) {
        return currencyFormat(item.discount.price);
    }

    return null;
}

export function posPriceBeforeDiscountNum(item) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            return Number(priorityPromo.price_normal);
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            return Number(validPromo.price_normal);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        return Number(item.product_prices[0].price_normal);
    }

    if (item.product_category_prices.length > 0) {
        const pric = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_PRICE_TYPE');
        if (pric && pric.discount) {
            return Number(pric.discount.price);
        }
    }

    if (item.discount) {
        return Number(item.discount.price);
    }

    return Number(item.price);
}

export function posPriceNum(item, is_ecommerce = null) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            return Number(priorityPromo.price_sale);
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            return Number(validPromo.price_sale);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        if (is_ecommerce === true)
            return Number(item.product_prices[0].price_ecommerce);
        else {
            const validPrice = item.product_prices
                .filter(price => isPriceActive(price))
                .sort((a, b) => {
                    const dateA = new Date(a.group_product_price.product_price_dates[0]?.date_start || 0);
                    const dateB = new Date(b.group_product_price.product_price_dates[0]?.date_start || 0);
                    return dateA - dateB;
                })[0];
            if (validPrice) {
                return Number(validPrice.price_after_discount);
            }else{
                return Number(item.product_prices[0].price_after_discount);
            }
        }
    }

    if (item.product_category_prices.length > 0) {
        const dis = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_DISCOUNT_TYPE');
        const pric = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_PRICE_TYPE');
        if (pric && pric.discount) {
            const price_before = Number(pric.discount.price) * (Number(dis.discount.discount_percent) / 100);
            const price_after = Number(pric.discount.price) - price_before;
            return Number(price_after);
        }
        if (pric) {
            return Number(pric.price);
        }
    }

    if (item.discount) {
        if (Number(item.discount.price_after_discount) === 0) {
            return Number(item.price);
        }
        return Number(item.discount.price_after_discount);
    }

    return Number(item.price);
}

export function posPriceDiscountPercent(item, is_ecommerce = null) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            if (priorityPromo.discount_percent_2 !== null && Number(priorityPromo.discount_percent_2) !== 0) {
                return `${priorityPromo.discount_percent}% + ${priorityPromo.discount_percent_2}%`;
            } else {
                return `${priorityPromo.discount_percent}%`;
            }
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            if (validPromo.discount_percent_2 !== null && Number(validPromo.discount_percent_2) !== 0) {
                return `${validPromo.discount_percent}% + ${validPromo.discount_percent_2}%`;
            } else {
                return `${validPromo.discount_percent}%`;
            }
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        if (is_ecommerce === true)
            return `${Number(item.product_prices[0].discount_percent_ecommerce)}%`;
        else {
            const validPrice = item.product_prices
                .filter(price => isPriceActive(price))
                .sort((a, b) => {
                    const dateA = new Date(a.group_product_price.product_price_dates[0]?.date_start || 0);
                    const dateB = new Date(b.group_product_price.product_price_dates[0]?.date_start || 0);
                    return dateA - dateB;
                })[0];
            if (validPrice) {
                return `${Number(validPrice.discount_percent)}%`;
            }else{
                return `${Number(item.product_prices[0].discount_percent)}%`;
            }
        }
    }

    if (item.product_category_prices.length > 0) {
        const disc = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_DISCOUNT_TYPE');
        if (disc) {
            const discountPercent = Number(disc.discount.discount_percent);
            const discountPercent2 = disc.discount.discount_percent_2 ? Number(disc.discount.discount_percent_2) : 0;
            if (discountPercent2 > 0) {
                return `${discountPercent}%+${discountPercent2}%`;
            }
            return `${discountPercent}%`;
        }
    }

    if (item.discount) {
        const discountPercent = Number(item.discount.discount_percent);
        const discountPercent2 = item.discount.discount_percent_2 ? Number(item.discount.discount_percent_2) : 0;
        if (discountPercent2 > 0) {
            return `${discountPercent}% + ${discountPercent2}%`;
        }
        return `${discountPercent}%`;
    }

    return null;
}

export function posPriceDiscountPercentNum(item, num, is_ecommerce = null) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            return num === 1 ? Number(priorityPromo.discount_percent) : Number(priorityPromo.discount_percent_2);
        }

        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            return num === 1 ? Number(validPromo.discount_percent) : Number(validPromo.discount_percent_2);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        if (is_ecommerce === true)
            return Number(item.product_prices[0].discount_percent_ecommerce);
        else {
            const validPrice = item.product_prices
                .filter(price => isPriceActive(price))
                .sort((a, b) => {
                    const dateA = new Date(a.group_product_price.product_price_dates[0]?.date_start || 0);
                    const dateB = new Date(b.group_product_price.product_price_dates[0]?.date_start || 0);
                    return dateA - dateB;
                })[0];
            if (validPrice) {
                return Number(validPrice.discount_percent);
            }else{
                return Number(item.product_prices[0].discount_percent);
            }
        }
    }

    if (item.product_category_prices.length > 0) {
        const disc = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_DISCOUNT_TYPE');
        if (disc) {
            if (num === 1) {
                return Number(disc.discount.discount_percent);
            }
            return Number(disc.discount.discount_percent_2);
        }
    }

    if (item.discount) {
        if (num === 1) {
            return Number(item.discount.discount_percent);
        }
        return Number(item.discount.discount_percent_2);
    }

    return null;
}

export function posPriceDiscountPrice(item, is_ecommerce = null) {
    if (item.product_promos && item.product_promos.length > 0) {
        const priorityPromo = item.product_promos.find(promo => promo.group_product_promo.is_priority === true);
        if (isPromoActive(priorityPromo)) {
            const sale = Number(priorityPromo.price_sale);
            const normal = Number(priorityPromo.price_normal);
            return Number(normal - sale);
        }
        const validPromo = item.product_promos
            .filter(promo => isPromoActive(promo))
            .sort((a, b) => {
                const dateA = new Date(a.group_product_promo.product_promo_dates[0]?.date_start || 0);
                const dateB = new Date(b.group_product_promo.product_promo_dates[0]?.date_start || 0);
                return dateA - dateB;
            })[0];

        if (validPromo) {
            const sale = Number(validPromo.price_sale);
            const normal = Number(validPromo.price_normal);
            return Number(normal - sale);
        }
    }

    if (item.product_prices !== undefined && item.product_prices.length > 0) {
        if (is_ecommerce === true)
            return Number(item.product_prices[0].price_normal) - Number(item.product_prices[0].price_ecommerce);
        else {
            const validPrice = item.product_prices
                .filter(price => isPriceActive(price))
                .sort((a, b) => {
                    const dateA = new Date(a.group_product_price.product_price_dates[0]?.date_start || 0);
                    const dateB = new Date(b.group_product_price.product_price_dates[0]?.date_start || 0);
                    return dateA - dateB;
                })[0];
            if (validPrice) {
                return Number(validPrice.price_normal) - Number(validPrice.price_after_discount);
            }else{
                return Number(item.product_prices[0].price_normal) - Number(item.product_prices[0].price_after_discount);
            }
        }
    }

    if (item.product_category_prices.length > 0) {
        const dis = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_DISCOUNT_TYPE');
        const pric = item.product_category_prices.find(o => o.salesAdjustmentType === 'ITEM_PRICE_TYPE');
        if (pric && pric.discount) {
            const price_before = Number(pric.discount.price) * (Number(dis.discount.discount_percent) / 100);
            return Number(price_before);
        }
    }

    if (item.discount) {
        return Number(item.discount.discount_price);
    }

    return null;
}