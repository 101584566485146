
import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { UnorderedListOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import dayjs from "dayjs";
import { dateFormat } from '../../Utils/formatDate';
import { currencyFormat } from '../../Utils/formatCurrency';
import moment from 'moment';


function ListVoucher() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [voucher, setVoucher] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalGroup, setIsModalGroup] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [category, setCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [form] = Form.useForm();
    const [formVoucher] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [isDisForm, setIsDisForm] = useState(true);
    const [voucherData, setVoucherData] = useState();

    // //Parameter
    const titlePage = 'Voucher';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Voucher',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (id, record, index) => {
                return dateFormat(record.created_at);
            },
        },
        {
            title: 'Tanggal Rubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (id, record, index) => {
                if (record.updated_at !== null)
                    return dateFormat(record.updated_at);
                else
                    return "-";
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" color='blue' style={{ marginRight: 10 }} onClick={() => openModal('detail', record)}>
                            <UnorderedListOutlined />
                            <b style={{ marginLeft: 5 }}>Detail</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    const voucherColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            
            key: "no",
            render: (id, record, index) => { ++index; return (pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Voucher',
            dataIndex: 'voucher_code',
            key: "voucher_code",
        },
        {
            title: 'Nama Voucher',
            dataIndex: 'voucher_name',
            key: "voucher_name",
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Min. Pembelian',
            dataIndex: 'min_buy',
            key: "min_buy",
            render: (id, record, index) => {
                return currencyFormat(record.min_buy);
            }
        },
        {
            title: 'Diskon (%)',
            dataIndex: 'discount_percent',
            key: "discount_percent",
            render: (id, record, index) => {
                if (record.discount_percent !== null && record.discount_percent !== undefined)
                    return record.discount_percent + '%';
                else
                    return '-';
            },
        },
        {
            title: 'Maks. Diskon (Rp)',
            dataIndex: 'max_price',
            key: "max_price",
            render: (id, record, index) => {
                if (record.max_price !== null && record.max_price !== undefined)
                    return currencyFormat(record.max_price);
                else
                    return '-';
            },
        },
        {
            title: 'Harga Diskon (Rp)',
            dataIndex: 'discount_price',
            key: "discount_price",
            render: (id, record, index) => {
                if (record.discount_price !== null && record.discount_price !== undefined)
                    return currencyFormat(record.discount_price);
                else
                    return '-';
            },
        },
    ];

    useEffect(() => {
        if (category.length === 0) {
            getApi('/category?nopage=true', 'category');
        }
        getApi('/groupvoucher?search=' + search + '&page=' + page.pagination.current, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (url, type) => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                    case 'category':
                        var category = res.data;
                        var row = [];
                        for (let i = 0; i < category.length; i++) {
                            row.push({
                                value: category[i].category_id,
                                label: category[i].category_name,
                            });
                        }
                        setCategory(row);
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                    case 'detail':
                        form.setFieldValue('name', res.data.name);
                        setVoucher(res.data.vouchers);
                        setdataModal(data)
                        setIsModalGroup(true);
                        // setIsModal(true);
                        setLoading(false);
                        break;

                    default:
                        break;
                }
            } else {
                setLoading(false);
                setLoadingBtn(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/voucher';
        if (method === 'DELETE') {
            url = '/groupvoucher/' + values.group_voucher_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const openModal = (type, data) => {
        if (type === 'detail') {
            getApi('/groupvoucher/' + data.group_voucher_id, 'detail');
        } else {
            setVoucher([]);
            // setIsModal(true);
            setIsModalGroup(true);
        }
    }

    const onFinish = (ev) => {
        var row = voucherData;
        if (isDisForm === true) {
            delete row.discount_price;
        } else {
            delete row.discount_percent;
            delete row.max_price;
        }
        ev = { ...ev, ...row };
        if (dataModal != null) {
            ev.voucher_id = dataModal.voucher_id;
            postApi('PUT', ev);
        } else {
            postApi('POST', ev);
        }
    }

    const closeModal = () => {
        setIsModalGroup(false);
        setdataModal(null);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const formDist = (type) => {
        if (type === 'discount') {
            form.setFieldsValue({
                discount_price: null
            });
            setIsDisForm(true);
        } else {
            form.setFieldsValue({
                discount_percent: null,
                max_price: null,
            });
            setIsDisForm(false);
        }
    }

    const onFinishVoucher = (ev) => {
        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var row = [];
        // const start = parseInt(ev.sequence_start, 10);
        // const max = parseInt(ev.sequence_end, 10);
        const maxLength = Math.max(ev.sequence_start.length, ev.sequence_end.length);
        // var code = generateVoucherCodes(ev.voucher_code, ev.sequence_start, ev.sequence_end);
        for (let i = Number(ev.sequence_start); i < Number(ev.sequence_end); i++) {

            const formattedNumber = i.toString().padStart(maxLength, '0');
            const voucherCode = `${ev.voucher_code}${formattedNumber}`;
            row.push({
                voucher_code: voucherCode,
                desc: ev.desc,
                discount_percent: ev.discount_percent,
                discount_price: ev.discount_price,
                max_price: ev.max_price,
                min_buy: ev.min_buy,
                sequence_end: ev.sequence_end,
                sequence_start: ev.sequence_start,
                discount_date_start: moment(ev.discount_date_start).format(dateFormat),
                discount_date_end: moment(ev.discount_date_start).format(dateFormat),
                voucher_name: ev.voucher_name
            });
        }
        setVoucher(row);
        setVoucherData({
            voucher_code: ev.voucher_code,
            desc: ev.desc,
            discount_percent: ev.discount_percent,
            discount_price: ev.discount_price,
            max_price: ev.max_price,
            min_buy: ev.min_buy,
            discount_date_start: moment(ev.discount_date_start).format(dateFormat),
            discount_date_end: moment(ev.discount_date_start).format(dateFormat),
            voucher_name: ev.voucher_name,
            sequence_start: ev.sequence_start,
            sequence_end: ev.sequence_end,
            category_id: ev.category_id
        });
        formVoucher.resetFields();
        setIsModal(false);
    }

    // function generateVoucherCodes(voucherPrefix, startNo, maxNo) {
    //     const start = parseInt(startNo, 10);
    //     const max = parseInt(maxNo, 10);
    //     const maxLength = Math.max(startNo.length, maxNo.length);
    //     const vouchers = [];
    //     for (let i = start; i <= max; i++) {
    //         const formattedNumber = i.toString().padStart(maxLength, '0');
    //         const voucherCode = `${voucherPrefix}${formattedNumber}`;
    //         vouchers.push(voucherCode);
    //     }
    //     return vouchers;
    // }

    const handleTableChangeVoucher = (pagination) => {
        setPagination(pagination);
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Voucher</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Voucher</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal width={800} title="Form Voucher" open={isModalGroup} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan"
                footer={
                    dataModal === null ? (
                        <>
                            <Button onClick={closeModal}>Cancel</Button>
                            <Button type="primary" onClick={form.submit}>Simpan</Button>
                        </>
                    ) : (
                        <Button onClick={closeModal}>Close</Button>
                    )
                }>
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="Nama"
                        rules={[
                            {
                                required: true,
                                message: 'Nama kosong!'
                            },
                        ]}
                    >
                        <Input placeholder='Nama' />
                    </Form.Item>
                </Form>
                <h4>List Voucher</h4>
                {
                    dataModal === null &&
                    <Button type='primary' onClick={() => setIsModal(true)} style={{ fontWeight: "bold", marginBottom: 10 }}>Tambah Voucher</Button>
                }
                <Table
                    size="middle"
                    columns={voucherColumns}
                    pagination={pagination}
                    dataSource={voucher}
                    loading={loading}
                    onChange={handleTableChangeVoucher}
                />
            </Modal>
            <Modal title="Form Voucher" open={isModal} onOk={formVoucher.submit} onCancel={() => setIsModal(false)} okText="Simpan" >
                <Form form={formVoucher} name="form_voucher" layout="vertical" onFinish={onFinishVoucher}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                            <Form.Item
                                name="voucher_code"
                                label="Kode Voucher"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kode Voucher kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='Kode voucher' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="sequence_start"
                                label="No urut mulai"
                                rules={[
                                    {
                                        required: true,
                                        message: 'No urut mulai kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='No urut mulai' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="sequence_end"
                                label="No urut akhir"
                                rules={[
                                    {
                                        required: true,
                                        message: 'No urut akhir kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='No urut akhir' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="voucher_name"
                        label="Nama Voucher"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Voucher kosong!'
                            },
                        ]}
                    >
                        <Input placeholder='Nama voucher' />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label="Deskripsi"
                        rules={[
                            {
                                required: false,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea placeholder='Deskripsi' />
                    </Form.Item>
                    <Form.Item
                        name="min_buy"
                        label="Minimal Pembelian"
                        rules={[
                            {
                                required: false,
                                message: 'Minimal Pembelian kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder='Minimal Pembelian'
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="start_datetime"
                        label="Tanggal & Waktu Mulai"
                        rules={[
                            {
                                required: false,
                                message: 'Tanggal & Waktu Mulai kosong!'
                            },
                        ]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="end_datetime"
                        label="Tanggal & Waktu Berakhir"
                        rules={[
                            {
                                required: false,
                                message: 'Tanggal & Waktu Berakhir kosong!'
                            },
                        ]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="category_id"
                        label="Kategori Produk"
                        rules={[
                            {
                                required: false,
                                message: 'Pilih Kategori Produk!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Kategori"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={category}
                        />
                    </Form.Item>
                    <Row style={{ marginBottom: 10 }}>
                        <Button type={isDisForm === true ? 'primary' : 'default'} onClick={() => formDist('discount')}>Diskon</Button>
                        <Button style={{ marginLeft: 10 }} type={isDisForm === false ? 'primary' : 'default'} onClick={() => formDist('price')}>Harga</Button>
                    </Row>
                    {
                        isDisForm === true ?
                            <>
                                <Form.Item
                                    name="discount_percent"
                                    label="Diskon (%)"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Diskon % kosong!'
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder='Diskon %'
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="max_price"
                                    label="Maks. Harga Diskon"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Maks. Harga Diskon kosong!'
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder='Maks. harga diskon'
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </>
                            :
                            <Form.Item
                                name="discount_price"
                                label="Diskon (Harga)"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Diskon harga kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder='Diskon harga'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    );
}

export default ListVoucher;