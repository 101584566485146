
import { Alert, Button, Col, Modal, Row, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import Search from 'antd/es/input/Search';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';


function ProductPromo() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(0);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    // //Parameter
    const titlePage = 'Produk Promo Set Periode';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
             sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Produk Promo',
            dataIndex: 'title',
            key: "title",
             sorter: false,
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
             sorter: false,
            render: (text, record) => {
                if (record.created_at !== null)
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
             sorter: false,
            render: (text, record) => {
                if (record.updated_at !== null)
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formProductPromo', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/productpromo?search=' + search + '&page=' + page.pagination.current, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);


    const handleUpload = (file) => {
        if (file) {
            setBtnLoading(true);
            const form = new FormData();
            form.append('file', file);
            getApi('POST', '/importproductpromo', 'import', form);
        } else {
            // Tampilkan pesan kesalahan jika tidak ada file yang dipilih
            console.error('File belum dipilih');
        }
    };


    const getApi = (method, url, type, data = null) => {
        setIsAlert(false);
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        if (type === 'download') {
            dataReq.responseType = 'blob';
        }
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.data;
                        setData(item);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'del':
                        setIsAlert(true);
                        setTextAlert(res.message);
                        setLoad(load + 1);
                        setLoading(false);
                        break;
                    case 'import':
                        setUploadModalVisible(false)
                        // setIsAlert(true);
                        setBtnLoading(false);
                        // setTextAlert(res.message);
                        modalSuccess(res.message);
                        setLoad(load + 1);
                        setLoading(false);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }else{
                setUploadModalVisible(false)
                modalError(res.message);
                setBtnLoading(false);
                setLoading(false);
                setLoad(load + 1);
            }
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/productpromo/' + v.group_product_promo_id, 'del'),
        });
    }

    function downloadFile() {
        setLoading(true);
        axios({
            url: '/assets/TEMPLATE_PRODUK_PROMO.xlsx', // URL file di dalam proyek React Anda
            method: 'GET',
            responseType: 'blob', // Set responseType sebagai 'blob' untuk unduhan file
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'TEMPLATE_PRODUK_PROMO.xlsx');
                document.body.appendChild(link);
                link.click();
                // Hapus URL objek setelah unduhan selesai
                window.URL.revokeObjectURL(url);
                setLoading(false);
                setDownloadModalVisible(false)
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    }

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const handleTableChange = (pagination) => {
        setPage({
            pagination,
        });
        setLoad(load + 1);
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Produk Promo</h3>
            <Row>
                <Col span={6}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={14}>
                    <Button style={{ marginLeft: 10, marginRight: 10 }} type="primary" onClick={() => setUploadModalVisible(true)}>
                        Unggah File Excel
                    </Button>
                    <Button type="primary" onClick={() => setDownloadModalVisible(true)}>
                        Download Template
                    </Button>

                    <Modal
                        title="Download Template Excel"
                        visible={downloadModalVisible}
                        onCancel={() => setDownloadModalVisible(false)}
                        footer={null}
                    >
                        <p>Apakah Anda yakin ingin mengunduh template Excel?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Button onClick={() => setDownloadModalVisible(false)} style={{ marginRight: '8px' }}>
                                Batal
                            </Button>
                            <Button type="primary" onClick={downloadFile}>
                                Unduh
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        title="Unggah File Excel"
                        visible={uploadModalVisible}
                        onCancel={() => setUploadModalVisible(false)}
                        footer={null}
                    >
                        <Upload
                            accept=".xlsx, .xls"
                            beforeUpload={false}
                            onChange={(info) => {
                                setFile(info.file.originFileObj);
                            }}
                            showUploadList={false} // Menyembunyikan daftar file yang diunggah
                        >
                            <Button icon={<UploadOutlined />}>Pilih File</Button>
                        </Upload>
                        {!file && (
                            <p>Silakan pilih file terlebih dahulu</p>
                        )}
                        {file && (
                            <p>File yang akan diunggah: {file.name}</p>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button onClick={() => setUploadModalVisible(false)} style={{ marginRight: '8px' }}>Batal</Button>
                            <Button type="primary" disabled={btnLoading} onClick={() => handleUpload(file)} icon={<UploadOutlined />}>{btnLoading === true ? 'Loading...' : 'Unggah'}</Button>

                        </div>
                    </Modal>
                </Col>
                <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => navigate('/formProductPromo')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={page.pagination}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ProductPromo;